<template>
  <div>
    <b-modal
      ref="add-member-modal-1"
      centered
      hide-footer
      hide-header
      class="position-relative"
    >
      <div class="add-member-to-group d-flex justify-content-center align-content-center align-items-center modal1">
        <b-row
          class="d-block"
        >
          <div class="closediv">
            <b-button
              variant="transparent"
              class="closebtn"
              @click="hide()"
            >
              <feather-icon
                icon="XIcon"
                class="cursor-pointer"
                size="16"
              />
            </b-button>
          </div>
          <div class="d-inline-block d-flex justify-content-center align-content-center align-items-center mt-2 mb-2">
            <span class="font-weight-bolder heading1">
              Add Member To Group
            </span>
          </div>
          <div class="d-inline-block d-flex justify-content-center align-content-center align-items-center m-1">
            <div
              id="form"
              class="form"
            >
              <validation-observer ref="addMemberToGroupForm">
                <b-form
                  class="mt-2"
                  @submit.prevent
                >
                  <b-col class="mb-1">
                    <b-form-group
                      text-bolder
                      label="Member Email"
                      class="label"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="Member Email"
                        rules="required|email"
                      >
                        <b-form-input
                          id="input-1"
                          v-model="member.email"
                          placeholder="Enter Member Email"
                          trim
                          class="input1"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- Role of Member-->
                  <b-col class="mb-2">
                    <b-form-group
                      text-bolder
                      label="Role of Member"
                      class="label"
                    >
                      <v-select
                        v-model="currentState"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        select-size="3"
                        label="name"
                        :clearable="false"
                        :disabled="!isOwner()"
                        :options="availableRoles"
                      />
                    </b-form-group>
                  </b-col>
                  <!--permission checkboxes-->
                  <b-col class="mb-2">
                    <b-form-group
                      label="Select Permissions of Member:"
                      text-bolder
                      class="label"
                    >
                      <b-form-checkbox-group
                        id="checkbox-group-1"
                        v-model="member.permissions"
                        name="flavour-1"
                        class="demo-inline-spacing"
                      >
                        <b-form-group
                          v-if="getSelectedGroupLauncherPermissions"
                          label="Simi Launcher"
                          text-bolder
                          class="label"
                        >
                          <span
                            v-for="permission in getMemberPermissionsInSelectedGroup"
                            :key="permission.name"
                          >
                            <b-form-checkbox
                              v-if="filterFeatureNameForLauncher(permission.feature.name)"
                              :value="permission.feature.id"
                              :disabled="!isMember() || isTargetAdsOrCampaignAllow(permission.feature.id)"
                              class="mt-2"
                              @change="permissionChangeHandler($event, permission.feature.id, permission.feature.slug)"
                            >
                              <span class="text-capitalize">{{ filterFeatureNameForLauncher(permission.feature.name) }}</span>
                            </b-form-checkbox>
                          </span>
                        </b-form-group>
                      </b-form-checkbox-group>
                      <b-form-checkbox-group
                        id="checkbox-group-1"
                        v-model="member.permissions"
                        name="flavour-1"
                        class="demo-inline-spacing"
                      >
                        <b-form-group
                          v-if="getSelectedGroupIotPermissions"
                          label="Simi Smart"
                          text-bolder
                          class="label"
                        >
                          <span
                            v-for="permission in getMemberPermissionsInSelectedGroup"
                            :key="permission.name"
                          >

                            <b-form-checkbox
                              v-if="filterFeatureNameForIOT(permission.feature.name)"
                              :value="permission.feature.id"
                              :disabled="!isMember()"
                              size="8"
                              class="mt-2"
                            >
                              <span class="text-capitalize">{{ filterFeatureNameForIOT(permission.feature.name) }}</span>
                            </b-form-checkbox>
                          </span>
                        </b-form-group>
                      </b-form-checkbox-group>
                    </b-form-group>
                  </b-col>
                  <!-- submit buttons -->
                  <div
                    class="form-group block mb-2 mx-3"
                  >
                    <b-button
                      block
                      variant="primary"
                      :disabled="spinner"
                      type="submit"
                      @click="sendInvitationToMember($event)"
                    >
                      <div
                        v-if="spinner"
                        class="spinner"
                      >
                        <b-spinner
                          small
                        />
                        <span class="sr-only">Loading...</span>
                      </div>
                      Send Invitation
                    </b-button>
                  </div>
                </b-form>
              </validation-observer>
            </div>
          </div>
        </b-row>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton,
  BModal,
  BForm,
  BFormInput,
  BFormGroup,
  VBModal,
  BRow,
  BCol,
  BSpinner,
  BFormCheckbox,
  BFormCheckboxGroup,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import Vuesax from 'vuesax'
import 'vuesax/dist/vuesax.css'
import Vue from 'vue'
import MemberType from '@/common/enums/memberTypeEnum'
import { showErrorMessage, showToast } from '@/common/global/functions'
import store from '@/store'

Vue.use(Vuesax)
Vue.directive('ripple', Ripple)
export default {
  components: {
    vSelect,
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BModal,
    BRow,
    BCol,
    BSpinner,
    BFormCheckbox,
    BFormCheckboxGroup,
    // validations
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      MemberType,
      spinner: false,
      spinnerOfRoleSwitch: false,
      currentState: 'Member',
      memberStatus: 'Member',
      launcherName: true,
      iotName: true,
      availableRoles: ['Member', 'Super member'],
      member: {
        email: null,
        role: MemberType.MEMBER,
        permissions: [],
        userGroupId: Number(this.$route.query.groupId),
      },
      // validation
      required,
    }
  },
  computed: {
    getMemberPermissionsInSelectedGroup() {
      return this.getSelectedGroupMember ? this.getSelectedGroupMember.permissions : null
    },
    deviceFeatureId() {
      const permission = this.getMemberPermissionsInSelectedGroup.find(object => object.feature.slug === 'devices')
      if (permission) {
        return permission.feature.id
      }
      return null
    },
    mediaFeatureId() {
      const permission = this.getMemberPermissionsInSelectedGroup.find(object => object.feature.slug === 'media')
      if (permission) {
        return permission.feature.id
      }
      return null
    },
    bannerCampaignFeatureId() {
      const permission = this.getMemberPermissionsInSelectedGroup.find(object => object.feature.slug === 'banner-campaigns')
      if (permission) {
        return permission.feature.id
      }
      return null
    },
    targetAdsFeatureId() {
      const permission = this.getMemberPermissionsInSelectedGroup.find(object => object.feature.slug === 'target-ads')
      if (permission) {
        return permission.feature.id
      }
      return null
    },
    getSelectedGroupMember() {
      return this.$store.getters['user/getSelectedGroupMember']
    },
    getMemberRoleInSelectedGroup() {
      return this.getSelectedGroupMember ? this.getSelectedGroupMember.role : null
    },
    getSelectedGroupAvailableApplications() {
      return this.$store.getters['user/getSelectedGroupAvailableApplications']
    },
    getSelectedGroupIotPermissions() {
      return this.getSelectedGroupAvailableApplications ? this.getSelectedGroupAvailableApplications.isSimiIot : null
    },
    getSelectedGroupLauncherPermissions() {
      return this.getSelectedGroupAvailableApplications ? this.getSelectedGroupAvailableApplications.isSimiLauncher : null
    },
  },
  watch: {
    currentState: {
      handler(value) {
        if (value === 'Super member') {
          this.member.role = MemberType.SUPER_MEMBER
          this.member.permissions = this.getMemberPermissionsInSelectedGroup.map(permission => permission.feature.id)
          this.memberStatus = 'Super Member'
        } else {
          this.member.role = MemberType.MEMBER
          this.member.permissions = []
          this.memberStatus = 'Member'
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.member.userGroupId = Number(this.$route.query.groupId)
  },
  methods: {
    permissionChangeHandler(value, permissionId, permissionSlug) {
      if (permissionSlug === 'banner-campaigns') {
        if (value.includes(permissionId)) {
          if (!this.member.permissions.includes(this.deviceFeatureId)) {
            this.member.permissions.push(this.deviceFeatureId)
          }
          if (!this.member.permissions.includes(this.mediaFeatureId)) {
            this.member.permissions.push(this.mediaFeatureId)
          }
        } else {
          const indexOfDevices = this.member.permissions.indexOf(this.deviceFeatureId)
          if (indexOfDevices !== -1) {
            this.member.permissions.splice(indexOfDevices, 1)
          }
          if (!this.member.permissions.includes(this.targetAdsFeatureId)) {
            const indexOfMedia = this.member.permissions.indexOf(this.mediaFeatureId)
            if (indexOfMedia !== -1) {
              this.member.permissions.splice(indexOfMedia, 1)
            }
          }
        }
      } else if (permissionSlug === 'target-ads') {
        if (value.includes(permissionId)) {
          if (!this.member.permissions.includes(this.mediaFeatureId)) {
            this.member.permissions.push(this.mediaFeatureId)
          }
        } else if (!this.member.permissions.includes(this.bannerCampaignFeatureId)) {
          const indexOfMedia = this.member.permissions.indexOf(this.mediaFeatureId)
          if (indexOfMedia !== -1) {
            this.member.permissions.splice(indexOfMedia, 1)
          }
        }
      }
    },
    show() {
      this.member.userGroupId = Number(this.$route.query.groupId)
      this.member.role = MemberType.MEMBER
      this.member.permissions = []
      this.currentState = 'Member'
      this.member.email = ''
      this.$refs['add-member-modal-1'].show()
    },
    hide() {
      this.$refs['add-member-modal-1'].hide()
    },
    sendInvitationToMember() {
      this.$refs.addMemberToGroupForm.validate().then(async success => {
        if (success) {
          if (this.member.permissions.length === 0) {
            showToast('Add Group Member', 'Please select permissions!', 'danger', 4000)
          } else if (this.member.email === null) {
            showToast('Add Group Member', 'Please enter correct email!', 'danger', 4000)
          } else {
            this.spinner = true
            await this.addMemberToGroup()
            this.spinner = false
          }
        }
      })
    },
    isOwner() {
      return this.getMemberRoleInSelectedGroup === MemberType.OWNER
    },
    isMember() {
      return this.currentState === 'Member'
    },
    isTargetAdsOrCampaignAllow(permissionId) {
      if (permissionId === this.deviceFeatureId) {
        return this.member.permissions ? this.member.permissions.includes(this.bannerCampaignFeatureId) : null
      }
      if (permissionId === this.mediaFeatureId) {
        return this.member.permissions ? this.member.permissions.includes(this.bannerCampaignFeatureId) || this.member.permissions.includes(this.targetAdsFeatureId) : null
      }
      return null
    },
    async addMemberToGroup() {
      try {
        await this.$axios
          .post('user-has-group/add-member-into-group', this.member)
        await this.hide()
        await this.$emit('getAllMembersOfGroup')
        await store.dispatch('user/setAllUserGroupIfNotPresent', true)
        showToast('Add Group Member', 'Member has been added successfully!')
        this.member = {}
      } catch ({
        response: {
          data: {
            statusCode,
            message,
          },
        },
      }) {
        if (showErrorMessage(statusCode)) {
          showToast('Add Group Member', 'You are not authorized for this action!', 'danger')
          await store.dispatch('user/setAllUserGroupIfNotPresent', true)
          await this.$router.push('groups-listing')
        }
        await this.hide()
      }
    },
    filterFeatureNameForIOT(name) {
      if (name === 'Site') {
        return 'Site'
      }
      this.iotName = false
      return null
    },
    filterFeatureNameForLauncher(name) {
      if (name !== 'Site') {
        return name
      }
      this.launcherName = false
      return undefined
    },
  },
}
</script>
<style src="vue-it-bigger/dist/vue-it-bigger.min.css"></style>
<style lang="scss">
.add-member-to-group {
  .search {
    .vs__dropdown-menu {
      max-height: 80px !important;
      overflow-y: auto !important;
      width: 320px !important;
    }
    .vs__dropdown-toggle{
      max-height: 60px !important;
      overflow-y: auto !important;
      width: 320px !important;
    }
  }
  #form {
    .custom-control {
      min-width: 145px !important;
      max-width: 145px !important;
    }

  .custom-checkbox .custom-control-label,{
    font-size: 0.9rem !important;
  }
    .custom-checkbox .custom-control-label::before, .custom-checkbox .custom-control-label::after, .custom-radio .custom-control-label::before, .custom-radio .custom-control-label::after,{
      width: 16px !important;
      height: 16px !important;
    }
    .demo-inline-spacing {
      padding-inline: 40px !important;
    }
    .form-control-plaintext{
      font-weight: 600 !important;
    }
  }
  .v-select .dropdown-toggle .clear {
    display: none;
  }
  .form {
    padding-inline: 10px;
    min-width: 450px !important;
    @media (max-width: 639px) {
      min-width: 0px !important;
    }
  }
  .add{
    position: relative;
  }
  .head{
    font-size: 14px;
    line-height: 24px;
    color: #1f58b5;
    font-weight: 600;
    font-family: "Montserrat";
  }
  .spinner{
    text-align: center;
    z-index: 1070;
  }
  .switches {
    border-radius: 25px;
  }
  .vs-switch{
   width: 110px !important;
    font-weight: bold !important;
  }
}

</style>
